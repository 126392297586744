<template>
  <div>
    <template v-if="isLogin">
      <el-button v-if="isPro" class="upgradeButton" @click="upgradePlan">
        {{ $t('plans.extendPlan') }}
      </el-button>
      <el-button
        v-else-if="plansType !== 'free'"
        class="upgradeButton"
        @click="upgradePlan"
      >
        {{ $t('plans.upgrade') }}
      </el-button>
    </template>
    <router-link v-else :to="{ name: 'Login' }">
      <el-button v-if="plansType === 'free'" class="upgradeButton">
        {{ $t('plans.select') }}
      </el-button>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    isPro: {
      type: Boolean,
      default: false
    },
    plansType: {
      type: String,
      default: null
    }
  },
  methods: {
    upgradePlan() {
      this.$emit("upgradePlan");
    }
  }
};
</script>

<style scoped lang="scss">
.upgradeButton {
  background: var(--themeColor);
  color: #ffffff;
  width: 120px;
}
</style>