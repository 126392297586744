<template>
  <div class="container">
    <PageTitle class="pageTitle">
      {{ pageTitle }}
    </PageTitle>
    <PlanMenu />
    <div class="plans">
      <div class="pickPlanDesc">
        <h3>{{ $t("plans.pickAPlan") }}</h3>
        <strong class="slogan">{{ $t("plans.aimFor100") }}</strong>
        <div class="subSlogan">
          <strong>{{ $t("plans.or110or120") }}</strong>
        </div>
        <div class="desc">
          <QuestionCount
            :startAdornment="
              $t(
                'plans.getUnlimitedAccessToOver13400RealToeflQuestionAndExplanations1'
              )
            "
            :endAdornment="
              $t(
                'plans.getUnlimitedAccessToOver13400RealToeflQuestionAndExplanations2'
              )
            "
          />
        </div>
        <div :class="`features ${isLogin ? '' : 'isNotExistCurrentPlan'}`">
          <div>
            {{ $t("plans.accessOnAnyDevice") }}
            <span class="deviceIcon"><i class="fas fa-mobile-alt"/></span>
            <span class="deviceIcon"><i class="fas fa-tablet-alt"/></span>
            <span class="deviceIcon"><i class="fas fa-desktop"/></span>
          </div>
          <div>
            {{ $t("plans.practiceAnytime") }}
          </div>
          <div class="practiceItem">
            {{ $t("plans.ivyWayToefl") }}
          </div>
          <div>
            {{ $t("plans.unlimitedDailyPractices") }}
          </div>
          <div>
            {{ $t("plans.customVocabLists") }}
          </div>
          <div>
            {{ $t("plans.adFree") }}
          </div>
          <div>
            {{ $t("plans.freeToeflMaterials") }}
            <el-tooltip
              :content="$t('plans.downloadAllMaterials')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
          <div>
            {{ $t("plans.freeTestTakersScoreReport") }}
            <router-link :to="{ name: 'ToeflScorereport' }">
              <i class="fas fa-question-circle" />
            </router-link>
          </div>
          <div>
            {{ $t("plans.priorityGrading") }}
            <el-tooltip
              :content="$t('plans.priorityGradingTooltip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
          <div>
            {{ $t("plans.discountForGradingServices") }}
            <el-tooltip
              v-if="langIsEn"
              :content="$t('plans.discountForGradingServicesTooltip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip>
          </div>
          <div>
            {{ $t("plans.AI instant Grading and Commenting") }}
            <!-- <el-tooltip
              v-if="langIsEn"
              :content="$t('plans.discountForGradingServicesTooltip')"
              placement="top"
              popper-class="tooltipColor"
            >
              <span class="action-icon">
                <i class="fas fa-question-circle" />
              </span>
            </el-tooltip> -->
          </div>
        </div>
      </div>
      <AvailablePlan
        v-if="packages.length > 0"
        :isLogin="isLogin"
        :isPro="isPro"
        :langIsEn="langIsEn"
        :proTimeLimited="proTimeLimited"
        @upgradeThirtyDaysVip="() => upgradePlans('unlimited_thirty_days')"
        @upgradeSixMonthsVip="() => upgradePlans('unlimited_six_months')"
        @upgradeYearVip="() => upgradePlans('unlimited_a_year')"
        :tCoins="packages"
        :isTW="isTW"
        :subscriptionsStatus="subscriptionsStatus"
      />
      <MobileAvailablePlan
        v-if="packages.length > 0"
        :isLogin="isLogin"
        :isPro="isPro"
        :langIsEn="langIsEn"
        :proTimeLimited="proTimeLimited"
        @upgradeThirtyDaysVip="() => upgradePlans('unlimited_thirty_days')"
        @upgradeSixMonthsVip="() => upgradePlans('unlimited_six_months')"
        @upgradeYearVip="() => upgradePlans('unlimited_a_year')"
        :tCoins="packages"
        :isTW="isTW"
        :subscriptionsStatus="subscriptionsStatus"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import plansApi from "@/apis/plans";
import toeflApi from "@/apis/toefl.js";
import AvailablePlan from "@/views/toefl/plans/AvailablePlan.vue";
import MobileAvailablePlan from "@/views/toefl/plans/MobileAvailablePlan.vue";
import QuestionCount from "@/views/toefl/questionCount/Index.vue";
import PlanMenu from "@/views/toefl/plans/PlanMenu.vue";
import PageTitle from "@/components/PageTitle.vue";
import Plans from "@/apis/plans";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle}: ${this.$t("header.plans")} - ` + this.CompanyName
    };
  },
  components: {
    AvailablePlan,
    MobileAvailablePlan,
    QuestionCount,
    PlanMenu,
    PageTitle
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.PlansAndPricing");
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    proTimeLimited() {
      return this.$store.getters["user/getProTimeLimited"];
    },
    langIsEn() {
      return this.$store.getters["user/langShortValue"] === "en";
    },
    isTW() {
      return false;
    }
  },
  data() {
    return {
      packages: [],
      location: "TW",
      subscriptions: [],
      subscriptionsStatus: {
        subscriptionMonth: false,
        subscription3Months: false,
        subscription6Months: false
      }
    };
  },
  async created() {
    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    this.location = iso_code;

    const { packages } = await toeflApi.openPackages();
    this.packages = packages;
    this.getSubscriptions();
  },
  methods: {
    async getSubscriptions() {
      if (this.isPro) {
        this.subscriptionsStatus.subscriptionMonth = true;
        this.subscriptionsStatus.subscription3Months = true;
        this.subscriptionsStatus.subscription6Months = true;
      }

      // const res = await Plans.getSubscriptions();
      // this.subscriptions = res.subscriptions;
      // if (
      //   this.subscriptions.filter(subscription => {
      //     return subscription.cancel_at_period_end === 0;
      //   }).length > 0
      // ) {
      //   this.subscriptionsStatus.subscriptionMonth = true;
      //   this.subscriptionsStatus.subscription3Months = true;
      //   this.subscriptionsStatus.subscription6Months = true;
      // }


      // let vm = this;
      // if(this.subscriptions && this.subscriptions.length > 0){
      //   this.subscriptions.filter(subscription => {
      //     return subscription.cancel_at_period_end === 0;
      //   }).forEach(subscription => {
      //     if(subscription.package.recurring.interval === "month" && subscription.package.recurring.interval_count === 1 && subscription.cancel_at_period_end === 0){
      //       vm.subscriptionsStatus.subscriptionMonth = true;
      //     }
      //     if(subscription.package.recurring.interval === "month" && subscription.package.recurring.interval_count === 3 && subscription.cancel_at_period_end === 0){
      //       vm.subscriptionsStatus.subscription3Months = true;
      //     }
      //     if(subscription.package.recurring.interval === "month" && subscription.package.recurring.interval_count === 6 && subscription.cancel_at_period_end === 0){
      //       vm.subscriptionsStatus.subscription6Months= true;
      //     }
      //   });
      // }
    },
    async upgradePlans(category) {
      let extendInformation = {};
      let extendProTimeLimited = moment(this.proTimeLimited);
      switch (category) {
        case "unlimited_thirty_days":
          extendInformation = {
            month: 1,
            suffix: "",
            date: extendProTimeLimited.add(30, "days").format("YYYY-MM-DD")
          };
          break;
        case "unlimited_six_months":
          extendInformation = {
            month: 6,
            suffix: "s",
            date: extendProTimeLimited.add(180, "days").format("YYYY-MM-DD")
          };
          break;
        case "unlimited_a_year":
          extendInformation = {
            month: 12,
            suffix: "s",
            date: extendProTimeLimited.add(365, "days").format("YYYY-MM-DD")
          };
          break;
        default:
      }
      try {
        if (this.isPro) {
          await this.$confirm(
            this.$t("plans.extendNotice", extendInformation),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        }
      } catch (e) {
        return false;
      }
      try {
        await plansApi.upgradePlan(category);
        this.$message.success(this.$t("plans.planSuccessful"));
        this.$store.dispatch("user/getProfile");
      } catch (e) {
        //
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  max-width: 100vw;
  margin: 0px;
  padding: 40px 0px;
  background: #f4f5f8;

  .pageTitle {
    padding-left: 32px !important;
  }

  .plans {
    width: calc(100vw - 60px);
    margin: 0px auto;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: calc((100vw - 60px) / 4) auto;

    .pickPlanDesc {
      padding-top: 20px;
      .slogan {
        font-size: 42px;
        color: var(--themeColor);
      }
      .subSlogan {
        margin-top: -12px;
        font-size: 30px;
      }
      .desc {
        margin: 24px 0px 58px 0px;
        height: 93px;
      }

      .features {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        padding: 8px 4px 0px 8px;
        margin: 0px -10px 0px 20px;
        display: grid;
        grid-gap: 4px;
        grid-template-rows: repeat(10, 50px);

        .deviceIcon {
          margin-right: 6px;
        }

        .practiceItem {
          // margin-left: 16px;
        }
      }

      .isNotExistCurrentPlan {
        margin: 56px -10px 0px 20px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    .plans {
      .pickPlanDesc {
        .slogan {
          font-size: 36px;
        }
        .subSlogan {
          font-size: 24px;
        }
        .desc {
          height: 113px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .container {
    .plans {
      .pickPlanDesc {
        .features {
          margin: 119px -10px 0px 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .container .pageTitle {
    padding-left: 15px !important;
  }
  ::v-deep .el-menu.el-menu--horizontal {
    padding: 0px 15px;
  }
  .container {
    padding: 20px 0px;
    .plans {
      width: calc(100vw - 30px);
      margin: 0px auto;
      display: flex;
      flex-direction: column;

      .pickPlanDesc {
        .slogan {
          font-size: 42px;
          color: var(--themeColor);
        }
        .subSlogan {
          margin-top: -12px;
          font-size: 30px;
        }
        .desc {
          margin: 24px 0px 0px 0px;
          height: 60px;
        }

        .features {
          display: none;
        }
      }
    }
  }
}
</style>
