<template>
  <div class="availablePlan">
    <div class="plan">
      <div v-if="isLogin && !isPro" class="currentPlan">
        <i class="fas fa-check" />
        <strong>
          {{ $t("plans.currentPlan") }}
        </strong>
      </div>
      <div class="planContent">
        <h3>{{ $t("plans.free") }} - {{ $t("plans.lifetime") }}</h3>
        <strong class="tCoin">
          $0 USD
        </strong>
        <div class="discount"></div>
        <div class="featuresAvailable">
          <div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.accessOnAnyDevice") }}
              <span class="deviceIcon"><i class="fas fa-mobile-alt"/></span>
              <span class="deviceIcon"><i class="fas fa-tablet-alt"/></span>
              <span class="deviceIcon"><i class="fas fa-desktop"/></span>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.practiceAnytime") }}
            </div>
            <div class="practiceItem">
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.ivyWayToefl") }}
            </div>
            <div>
              <span class="isAvailableIcon">
                <i class="fas fa-times" />
              </span>
              {{ $t("plans.unlimitedDailyPractices") }}
              <el-tooltip
                :content="$t('plans.twoFullTestsAndFivePractices')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="isAvailableIcon">
                <i class="fas fa-times" />
              </span>
              {{ $t("plans.customVocabLists") }}
            </div>
            <div>
              <span class="isAvailableIcon">
                <i class="fas fa-times" />
              </span>
              {{ $t("plans.adFree") }}
            </div>
          </div>
          <div>
            <div>
              <span class="isAvailableIcon">
                <i class="fas fa-times" />
              </span>
              {{ $t("plans.freeToeflMaterials") }}
              <el-tooltip
                :content="$t('plans.downloadAllMaterials')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="isAvailableIcon">
                <i class="fas fa-times" />
              </span>
              {{ $t("plans.freeTestTakersScoreReport") }}
              <router-link :to="{ name: 'ToeflScorereport' }">
                <i class="fas fa-question-circle" />
              </router-link>
            </div>
            <div>
              <span class="isAvailableIcon">
                <i class="fas fa-times" />
              </span>
              {{ $t("plans.priorityGrading") }}
              <el-tooltip
                :content="$t('plans.priorityGradingTooltip')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="isAvailableIcon">
                <i class="fas fa-times" />
              </span>
              {{ $t("plans.discountForGradingServices") }}
              <el-tooltip
                v-if="langIsEn"
                :content="$t('plans.discountForGradingServicesTooltip')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="bottomApply">
          <UpgradeButton plansType="free" :isLogin="isLogin" />
        </div>
      </div>
    </div>
    <div class="plan">
      <div v-if="isLogin && isPro" class="currentPlan">
        <i class="fas fa-check" />
        <strong>
          {{ $t("plans.currentPlan") }}
        </strong>
        <br />
        <span class="timedLimited">
          <span class="text-success"> <i class="fas fa-crown"></i> Pro </span>
          <router-link
            :to="{
              name: 'Profile',
              query: {
                tab: 'plans'
              }
            }"
          >
            {{ `${$t("plans.planDuration")}${proTimeLimited} (GMT+8)` }}
          </router-link>
        </span>
      </div>
      <div class="planContent">
        <div class="duration">
          {{ $t("Pro.1 month") }}
        </div>
        <small class="text-info">
          {{ $t("Pro.Renew at") }} $20.00 USD
        </small>
        <div class="tCoin">
          <strong class="discountTCoin">
            $20.00 USD
          </strong>
          <div class="originTCoin">
            $40.00 USD
            <div v-if="langIsEn" class="removeLine removeLineEn" />
            <div v-else class="removeLine removeLineTw" />
          </div>
        </div>
        <div class="featuresAvailable">
          <div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.accessOnAnyDevice") }}
              <span class="deviceIcon"><i class="fas fa-mobile-alt"/></span>
              <span class="deviceIcon"><i class="fas fa-tablet-alt"/></span>
              <span class="deviceIcon"><i class="fas fa-desktop"/></span>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.practiceAnytime") }}
            </div>
            <div class="practiceItem">
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.ivyWayToefl") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.unlimitedDailyPractices") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.customVocabLists") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.adFree") }}
            </div>
          </div>
          <div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.freeToeflMaterials") }}
              <el-tooltip
                :content="$t('plans.downloadAllMaterials')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.freeTestTakersScoreReport") }}
              <router-link :to="{ name: 'ToeflScorereport' }">
                <i class="fas fa-question-circle" />
              </router-link>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.priorityGrading") }}
              <el-tooltip
                :content="$t('plans.priorityGradingTooltip')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.discountForGradingServices") }}
              <el-tooltip
                v-if="langIsEn"
                :content="$t('plans.discountForGradingServicesTooltip')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="bottomApply">
          <el-button
            v-show="subscriptionsStatus.subscriptionMonth"
            type="text"
            round
            disabled
            style="color:white"
          >
            <i class="fa fa-check"></i> {{ $t("Pro.Subscribed") }}
          </el-button>
          <el-button
            v-show="!subscriptionsStatus.subscriptionMonth"
            type="success"
            class="subscribe-button"
            @click="getPro('month', 1)"
            round
          >
            {{ $t("Pro.Subscribe") }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="plan">
      <div v-if="isLogin && isPro" class="currentPlan">
        <i class="fas fa-check" />
        <strong>
          {{ $t("plans.currentPlan") }}
        </strong>
        <br />
        <span class="timedLimited">
          <span class="text-success"> <i class="fas fa-crown"></i> Pro </span>
          <router-link
            :to="{
              name: 'Profile',
              query: {
                tab: 'plans'
              }
            }"
          >
            {{ `${$t("plans.planDuration")}${proTimeLimited} (GMT+8)` }}
          </router-link>
        </span>
      </div>
      <div class="planContent">
        <div class="duration">
          {{ $t("Pro.3 months") }}
        </div>
        <small class="text-info">
          {{ $t("Pro.Renew at") }} $50.00 USD
        </small>
        <div class="tCoin">
          <strong class="discountTCoin">
            $50.00 USD
          </strong>
          <div class="originTCoin">
            $120.00 USD
            <div v-if="langIsEn" class="removeLine removeLineEn" />
            <div v-else class="removeLine removeLineTw" />
          </div>
        </div>
        <div class="featuresAvailable">
          <div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.accessOnAnyDevice") }}
              <span class="deviceIcon"><i class="fas fa-mobile-alt"/></span>
              <span class="deviceIcon"><i class="fas fa-tablet-alt"/></span>
              <span class="deviceIcon"><i class="fas fa-desktop"/></span>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.practiceAnytime") }}
            </div>
            <div class="practiceItem">
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.ivyWayToefl") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.unlimitedDailyPractices") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.customVocabLists") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.adFree") }}
            </div>
          </div>
          <div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.freeToeflMaterials") }}
              <el-tooltip
                :content="$t('plans.downloadAllMaterials')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.freeTestTakersScoreReport") }}
              <router-link :to="{ name: 'ToeflScorereport' }">
                <i class="fas fa-question-circle" />
              </router-link>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.priorityGrading") }}
              <el-tooltip
                :content="$t('plans.priorityGradingTooltip')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.discountForGradingServices") }}
              <el-tooltip
                v-if="langIsEn"
                :content="$t('plans.discountForGradingServicesTooltip')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="bottomApply">
          <el-button
            v-show="subscriptionsStatus.subscription3Months"
            type="text"
            round
            disabled
            style="color:white"
          >
            <i class="fa fa-check"></i> {{ $t("Pro.Subscribed") }}
          </el-button>
          <el-button
            v-show="!subscriptionsStatus.subscription3Months"
            type="success"
            class="subscribe-button"
            @click="getPro('month', 3)"
            round
          >
            {{ $t("Pro.Subscribe") }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="plan">
      <div v-if="isLogin && isPro" class="currentPlan">
        <i class="fas fa-check" />
        <strong>
          {{ $t("plans.currentPlan") }}
        </strong>
        <br />
        <span class="timedLimited">
          <span class="text-success"> <i class="fas fa-crown"></i> Pro </span>
          <router-link
            :to="{
              name: 'Profile',
              query: {
                tab: 'plans'
              }
            }"
          >
            {{ `${$t("plans.planDuration")}${proTimeLimited} (GMT+8)` }}
          </router-link>
        </span>
      </div>
      <div class="planContent">
        <div class="duration">
          {{ $t("Pro.6 months") }}
        </div>
        <small class="text-info">
          {{ $t("Pro.Renew at") }} $88.00 USD
        </small>
        <div class="tCoin">
          <strong class="discountTCoin">
            $88.00 USD
          </strong>
          <div class="originTCoin">
            $240.00 USD
            <div v-if="langIsEn" class="removeLine removeLineEn" />
            <div v-else class="removeLine removeLineTw" />
          </div>
        </div>
        <div class="featuresAvailable">
          <div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.accessOnAnyDevice") }}
              <span class="deviceIcon"><i class="fas fa-mobile-alt"/></span>
              <span class="deviceIcon"><i class="fas fa-tablet-alt"/></span>
              <span class="deviceIcon"><i class="fas fa-desktop"/></span>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.practiceAnytime") }}
            </div>
            <div class="practiceItem">
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.ivyWayToefl") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.unlimitedDailyPractices") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.customVocabLists") }}
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.adFree") }}
            </div>
          </div>
          <div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.freeToeflMaterials") }}
              <el-tooltip
                :content="$t('plans.downloadAllMaterials')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.freeTestTakersScoreReport") }}
              <router-link :to="{ name: 'ToeflScorereport' }">
                <i class="fas fa-question-circle" />
              </router-link>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.priorityGrading") }}
              <el-tooltip
                :content="$t('plans.priorityGradingTooltip')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
            <div>
              <span class="available isAvailableIcon">
                <i class="fas fa-check" />
              </span>
              {{ $t("plans.discountForGradingServices") }}
              <el-tooltip
                v-if="langIsEn"
                :content="$t('plans.discountForGradingServicesTooltip')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="action-icon">
                  <i class="fas fa-question-circle" />
                </span>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="bottomApply">
          <el-button
            v-show="subscriptionsStatus.subscription6Months"
            type="text"
            round
            disabled
            style="color:white"
          >
            <i class="fa fa-check"></i> {{ $t("Pro.Subscribed") }}
          </el-button>
          <el-button
            v-show="!subscriptionsStatus.subscription6Months"
            type="success"
            class="subscribe-button"
            @click="getPro('month', 6)"
            round
          >
            {{ $t("Pro.Subscribe") }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
import UpgradeButton from "@/views/toefl/plans/UpgradeButton.vue";
import Plans from "@/apis/plans";

export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    isPro: {
      type: Boolean,
      default: false
    },
    proTimeLimited: {
      type: String,
      default: ""
    },
    isTW: {
      type: Boolean,
      default: false
    },
    langIsEn: {
      type: Boolean,
      default: false
    },
    tCoins: {
      type: Array,
      default: () => []
    },
    subscriptionsStatus: {
      type: Object,
      default: () => null
    }
  },
  components: {
    UpgradeButton
  },
  computed: {
    offersTime() {
      return "";
    },
    offersDiscount() {
      return "65";
    }
  },
  methods: {
    async getPro(interval, intervalCount) {
      const loading = this.$loading({
        lock: true,
        text: "Redirecting to payment, please wait.",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await Plans.subscribePro({
        interval: interval,
        interval_count: intervalCount,
        success_url: "https://testadmit.com/toefl/profile?tab=plans",
        cancel_url: "https://testadmit.com/toefl/profile?tab=plans"
      });
      window.location.href = res.url;
      // loading.close();
    },
    getMinComboPrice(type, currency, demandTCoins) {
      const isTW = currency === "TW";
      const fromBigToSmallPackages = [...this.tCoins].sort(
        (a, b) => b.point - a.point
      );
      let result = 0;
      let workDemandTCoins = Number(demandTCoins);
      while (workDemandTCoins > 0) {
        const targetPackage = fromBigToSmallPackages.find(
          tCoin => tCoin.point <= demandTCoins
        );
        if (type === "discount") {
          if (isTW) {
            result = Decimal.add(result, Number(targetPackage.price));
          } else {
            result = Decimal.add(
              result,
              Number(targetPackage.stripe_usd_price)
            );
          }
        } else if (type === "original") {
          result = Decimal.add(result, Number(targetPackage.original_price));
        }
        workDemandTCoins = Decimal.sub(workDemandTCoins, targetPackage.point);
      }
      return result;
    },
    getMinComboPriceWithUnit(type, currency, demandTCoins) {
      const isTW = currency === "TW";
      const result = this.getMinComboPrice(type, currency, demandTCoins);

      return `${isTW ? "" : "≈"}$${Number(result)} ${isTW ? "TWD" : "USD"}`;
    },
    getSaveUSD(months, tCoins) {
      const monthUSD = 18.88;
      const discountUSD = this.getMinComboPrice("discount", "US", tCoins);
      return Decimal.sub(Decimal.mul(monthUSD, months), discountUSD);
    }
  }
};
</script>

<style scoped lang="scss">
.availablePlan {
  display: none;
}

.deviceIcon {
  margin-right: 6px;
}

.timedLimited {
  color: #00000077;
}

@media screen and (max-width: 1220px) {
  .availablePlan {
    display: flex;
    flex-direction: column;

    .plan {
      background: #fff;
      box-shadow: 1px 1px 16px 4px #cccccc;
      margin-bottom: 24px;

      .currentPlan {
        border-bottom: 1px solid #ddd;
        padding: 10px 20px;
      }

      .planContent {
        padding: 24px 20px;

        .tCoin {
          margin-bottom: 1rem;
          font-size: 42px;
          color: #409eff;

          .originalTCoin {
            margin-bottom: -12px;
            font-size: 24px;
            display: block;
            color: #aaaaaa;
            position: relative;
          }

          .originalTCoin::after {
            content: "";
            width: 90px;
            border-top: 2px solid #aaaaaa;
            position: absolute;
            top: 18px;
            left: 0px;
            transform: rotate(-6deg);
          }

          .originTCoin {
            font-size: 20px;
            color: #aaaaaa;
            position: relative;
            line-height: 0.8;
            font-weight: bold;

            .removeLine {
              border-top: 2px solid #aaaaaa;
              position: absolute;
              top: 8px;
              transform: rotate(-6deg);
            }

            .removeLineEn {
              width: 80px;
            }

            .removeLineTw {
              width: 80px;
              transform: rotate(-10deg);
            }
          }
        }

        .featuresAvailable {
          display: flex;
          div {
            flex-grow: 1;
            margin-bottom: 4px;

            .isAvailableIcon {
              margin-right: 8px;
            }
            .available {
              color: red;
            }
          }

          .practiceItem {
            // margin-left: 16px;
          }
        }

        .discount {
          font-size: 24px;
          font-weight: bold;
          white-space: nowrap;
          margin: 12px 0px;

          .offers {
            font-size: 16px;
            color: red;
          }
        }

        .bottomApply {
          margin-top: 12px;
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .availablePlan {
    .planContent {
      .featuresAvailable {
        flex-direction: column;
      }
    }
  }
}
.subscribe-button {
  width: 120px;
  background-color: #409eff;
  border-color: #409eff;
}
</style>
